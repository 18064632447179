import React, {useEffect, useState} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {COLORS} from "../../utils/constants";

am4core.useTheme(am4themes_animated);

function SupplyPlanBarChart(props) {
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);
    console.log(props.data);

    // Add data
    chart.data = props.data;
    chart.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = props.category.value;
    categoryAxis.numberFormatter.numberFormat = "#,###.";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    // Create series
    function createSeries(field, name, color) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = props.category.value;
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
      series.columns.template.height = am4core.percent(90);
      series.columns.template.width = am4core.percent(70);
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.cornerRadiusBottomRight = 10;
      series.columns.template.fill = color;
      series.columns.template.stroke = color;
      series.sequencedInterpolation = true;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{valueX}";
      valueLabel.label.horizontalCenter = "left";
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;
      valueAxis.renderer.grid.template.strokeOpacity = 0;
    }

    createSeries("val_supply_plan", "Supply Plan", COLORS["val_supply_plan"]);
    createSeries("val_previsione", "Forecast", COLORS["val_previsione"]);

    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

    valueAxis.title.text = "Quantità (kg)";
    valueAxis.title.fontWeight = "bold";

    // Set cell size in pixels
    var cellSize = 70;
    chart.events.on("datavalidated", function (ev) {
      // Get objects of interest
      var chart = ev.target;
      var categoryAxis = chart.yAxes.getIndex(0);

      // Calculate how we need to adjust chart height
      var adjustHeight =
          chart.data.length * cellSize - categoryAxis.pixelHeight;

      // get current chart height
      var targetHeight = chart.pixelHeight + adjustHeight;

      // Set it on chart's container
      chart.svgContainer.htmlElement.style.height = targetHeight + "px";
    });

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [props.data, props.id, props.plant, props.category.value]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return <div id={props.id} style={{minHeight: "250px", padding: 0}}/>;
}

export default SupplyPlanBarChart;
