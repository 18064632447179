import React, {useState} from "react";

// Ui
import {Dialog, DialogContent, DialogTitle, LinearProgress, makeStyles, TextField} from "@material-ui/core";
import {InputAdornment, DialogContentText, Grid, Link, Typography, Button} from "@material-ui/core";
import {ArrowBackIos, Edit} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import ApiService from "../../services/ApiService";
import {useHistory} from "react-router-dom";
import theme from "../../utils/theme";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "46%",
    marginLeft: "2%",
    marginRight: "2%"
  },
  button: {
    marginTop: 25,
    marginLeft: 25
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitRight: {
    position: "absolute",
    right: 25,
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Profile() {
  const history = useHistory();
  const classes = useStyles();
  const [userData, setUserData] = useState(AuthenticationService.getUserData());
  const [loading, setLoading] = useState(false);
  const [toSave, setToSave] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(undefined)
  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState({value: false, msg: ""});
  const [newPassword, setNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState({value: false, msg: ""});
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState({value: false, msg: ""});
  const [passwordStatus, setPasswordStatus] = useState(undefined)

  function togglePassword() {
    setOpenPassword(!openPassword);
  }

  function updateField(key, value) {
    setToSave(true)
    setUserData({...userData, [key]: value})
  }

  const updateUser = async (userData) => {
    setLoading(true);
    try {
      let res = await ApiService.postApi(`users/${userData['id']}`, userData);
      console.log(res)
      setUpdateStatus(true)
      localStorage.setItem('logged_user', JSON.stringify(userData));
    } catch (err) {
      console.log(err)
      setUpdateStatus(false)
    }

    setLoading(false)
    setToSave(false)
  }

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (newPassword.length < 6 || newPassword.includes(' ')) {
      setErrorNewPassword({ value: true, msg: "Password non conforme"})
    } else if (confirmPassword !== newPassword) {
      setErrorNewPassword({ value: false, msg: ""})
      setErrorConfirmPassword({value: true, msg: "Le password non coincidono"})
    } else {
      setErrorNewPassword({ value: false, msg: ""})
      setErrorConfirmPassword({value: false, msg: ""})
      try {
        const data = {
          'email': userData['email'],
          'password': password,
          'new_password': newPassword
        }
        let res = await ApiService.postApi('auth/password/reset', data);
        console.log(res)
        setErrorPassword({ value: false, msg: ""})
        setPasswordStatus(true)
        setPassword("")
        setNewPassword("")
        setConfirmPassword("")
      } catch (err) {
        console.log(err)
        setErrorPassword({ value: true, msg: "Password errata"})
      }
    }
    setLoading(false)
  }

  function getDefaultValue(key) {
    return userData[key]
  }

  function readOnlyField(label, key) {
    return (
      <TextField
        label={label}
        className={classes.field}
        defaultValue={getDefaultValue(key)}
        disabled={true}
        variant="outlined"
        size="small"
      />
    )
  }

  function editableField(label, key) {
    return (
      <TextField
        label={label}
        className={classes.field}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Edit />
            </InputAdornment>
          )
        }}
        defaultValue={userData[key]}
        onChange={(e) => updateField(key, e.target.value)}
        variant="outlined"
        size="small"
      />
    )
  }

  return (
    <>
      <LinearProgress style={{
        visibility: loading ? 'visible' : 'hidden'
      }}/>
      <Typography variant="h4" display="inline" gutterBottom>
          <Link onClick={() => history.goBack()}>
            <ArrowBackIos />
          </Link>
          Profilo personale
      </Typography>
      <hr style={{marginBottom: 20}}/>
      {editableField("Nome Cognome", 'full_name')}
      {readOnlyField("Email", 'email')}
      <div>
        <Button variant="contained" onClick={() => updateUser(userData)} disabled={!toSave} className={classes.button}>
          Aggiorna
        </Button>
      </div>
      <div>
        <Button variant="contained" color="primary" onClick={togglePassword} className={classes.button} disabled>
          Modifica password
        </Button>
      </div>
      {updateStatus &&
        <Alert onClose={() => {setUpdateStatus(undefined)}} severity="success" className={classes.button}>
          Dati aggiornati correttamente
        </Alert>
      }
      {updateStatus === false &&
        <Alert onClose={() => {setUpdateStatus(undefined)}} severity="error" className={classes.button}>
          L'uid o la mail inseriti sono già presenti a sistema
        </Alert>
      }
      <Dialog
          open={openPassword}
          onClose={togglePassword}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Modifica password"}</DialogTitle>
            <DialogContent>
              <form className={classes.form} onSubmit={handleChangePassword}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Password attuale"
                  autoComplete="password"
                  type="password"
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errorPassword.value}
                  helperText={errorPassword.msg}
                />
                <DialogContentText style={{marginTop: theme.spacing(2)}}>
                  Inserire una password lunga almeno 6 caratteri, senza spazi
                </DialogContentText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Nuova password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={errorNewPassword.value}
                  helperText={errorNewPassword.msg}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Conferma password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={errorConfirmPassword.value}
                  helperText={errorConfirmPassword.msg}
                />
                <Grid container>
                  <Grid item xs={5}>
                    <Button onClick={togglePassword} variant="contained" className={classes.submit}>
                      Esci
                    </Button>
                  </Grid>
                  <Grid item xs={7}>
                    <Button type="submit" color="primary" variant="contained" className={classes.submitRight} disabled={loading}>
                      Invia
                    </Button>
                  </Grid>
                </Grid>
              </form>
              {passwordStatus &&
                <Alert onClose={() => {setPasswordStatus(undefined)}} severity="success" className={classes.button}>
                  Password aggiornata correttamente
                </Alert>
              }
            </DialogContent>
        </Dialog>
    </>
  );

}
