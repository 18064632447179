import React from "react";

import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";

const menu = [
  {
    to: "/home",
    text: "Home",
    icon: <HomeRoundedIcon />,
  },
  {
    to: "/gestione-aggregazione",
    text: "Gestione Aggregazione",
    icon: <PostAddOutlinedIcon />,
    // role: ["admin"],
  },
  {
    to: "/visualizzazione-modelli",
    text: "Visualizzazione Modelli",
    icon: <TrendingUpRoundedIcon />,
    // role: ["admin", "commerciale"],
  },
  {
    to: "/input-summary",
    text: "Input Summary",
    icon: <ListOutlinedIcon />,
    // role: ["admin", "commerciale"],
  },
  {
    to: "/revisione-domanda",
    text: "Revisione Domanda",
    icon: <RateReviewOutlinedIcon />,
    // role: ["admin"],
  },
  {
    to: "/make-or-buy",
    text: "Make or Buy",
    icon: <EqualizerOutlinedIcon />,
    // role: ["admin"],
  },
  {
    to: "/supply-plan",
    text: "Supply Plan",
    icon: <DonutLargeOutlinedIcon />,
    // role: ["admin"],
  },
  {
    divider: true,
  },
  {
    to: "/users",
    text: "Gestione Utenze",
    icon: <GroupAddIcon />,
    role: ["admin"],
  },
];

export default menu;
