import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { COLORS } from "../../utils/constants";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

function DemandRevisionChart(props) {
  const [chartRef, setChartRef] = useState(null);

  console.log("HI\n", props.data)

  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);
    let _data = _.sortBy(props.data, "dat_settimana");
    _data.slice(-1)[0]["bulletDisabled"] = false;

    chart.data = _data;
    chart.logo.disabled = true;
    chart.tapToActivate = false;
    chart.paddingBottom = 0;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.renderer.labels.template.fontSize = 11;
    dateAxis.renderer.maxLabelPosition = 0.98;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.title.text = "Quantità (kg)";
    valueAxis.title.fontWeight = "bold";

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.marginBottom = 10;
    chart.legend.fontSize = 12;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 11;
    markerTemplate.height = 11;

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#,###.";
    chart.language.locale["_thousandSeparator"] = ".";

    chart.maskBullets = false;
    chart.paddingRight = 30;

    let vertical_date1 = new Date(), vertical_date2 = new Date()
    if (_data.filter((i) => i.flg_periodo_riferimento).length > 0){
      vertical_date1 = new Date(_.minBy(
        _data.filter((i) => i.flg_periodo_riferimento),
        "dat_settimana"
      ).dat_settimana);
  
      vertical_date2 = new Date(_.maxBy(
        _data.filter((i) => i.flg_periodo_riferimento),
        "dat_settimana"
      ).dat_settimana);
    }

    let range = dateAxis.axisRanges.create();
    range.date = vertical_date1;
    range.grid.stroke = am4core.color("black");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;

    let range2 = dateAxis.axisRanges.create();
    range2.date = vertical_date2;
    range2.grid.stroke = am4core.color("black");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;

    // Create series
    function createSeries(field, name, color, label) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "dat_settimana";
      series.name = name;
      series.stroke = color;
      series.fill = color;
      series.tooltipText =
        "Settimana {dat_settimana} \n {name}: [bold]{valueY}[/]";
      let bullet = series.bullets.push(new am4charts.CircleBullet());
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.fontSize = 10;

      if (field === "val_safety_stock") {
        bullet.circle.radius = 0;
        series.segments.template.strokeWidth = 1;
      } else if (field === "val_stock") {
        bullet.circle.radius = 7;
        labelBullet.label.text = "{valueY}";
        labelBullet.label.paddingTop = 40;
      } else {
        series.segments.template.strokeWidth = 2;
      }

      if (field === "val_safety_stock") {
        labelBullet.disabled = true;
        labelBullet.propertyFields.disabled = "bulletDisabled";
        labelBullet.label.text = "Safety: {valueY}";
        labelBullet.horizontalCenter = "left";
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.paddingLeft = 10;
      }

      return series;
    }

    createSeries(
      "val_safety_stock",
      "Safety Stock",
      COLORS["val_safety_stock"],
      true
    );
    createSeries("val_ordine", "Ordinato", COLORS["val_ordine"]);
    createSeries("val_previsione", "Forecast", COLORS["val_previsione"]);
    createSeries(
      "val_proiezione_stock",
      "Proiezione Stock",
      COLORS["val_proiezione_stock"]
    );
    createSeries("val_stock", "Stock attuale", COLORS["val_stock"], true);

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [props.data, props.id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={props.id} style={{ minWidth: "100px", minHeight: "300px" }} />
  );
}

export default React.memo(DemandRevisionChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});
