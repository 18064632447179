// Api URL based on env
export const API_URL = "/api";


export const ROLES = [
  {value: "admin", label: "Admin"},
  {value: "commerciale", label: "Commerciale"},
  {value: "altro", label: "Altro"},
]

export const MTS_MTO = ['MTS', 'MTO']
export const PLANTS = ['A', 'B', 'Outsourcing']
export const FLAGS = {
    'flg_stock': 'Stock',
    'flg_pianificato_produzione': 'Pianificato Produzione',
    'flg_ordini': 'Ordini',
  }

export const FILTER_DIMENSION = [
  {value: "tipo_lavorazione", label: "Formato"},
  {value: "stock_order", label: "MTS/MTO"},
  {value: "stabilimento", label: "Stabilimento"},
]


export const COLORS = {
  "val_previsione": 'rgb(44,160,91)',
  "val_ordine": 'rgb(81,144,212)',
  "val_pianificato": 'rgb(1,79,51)',
  "val_supply_plan": 'rgb(0,57,76)',
  "val_stock": '#847b45',
  "val_stock_revisione": 'rgb(248,91,70)',
  "val_proiezione_stock": 'rgb(248,91,70)',
  "val_safety_stock": 'rgb(243,145,80)',
  "dat_settimana": 'rgb(57,57,57)',
  "A": 'rgb(0,57,76)',
  "B": 'rgb(2,119,157)',
  "outsourcing": 'rgb(1,124,81)',
}
