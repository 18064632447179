import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { COLORS } from "../../utils/constants";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

function PlantDemandBarChart(props) {
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);

    chart.data = _.sortBy(props.data.map(i => ({...i,
      percSP: i['val_capacita'] > 0 ? Math.round(i['val_supply_plan'] / i['val_capacita'] * 100) : 0,
      percRes: i['val_capacita'] > 0 ? Math.round(i['val_residuo'] / i['val_capacita'] * 100): 0,
    })), 'formato').reverse() ;
    chart.logo.disabled = true;
    chart.paddingRight = 30;
    chart.paddingLeft = 0;
    chart.paddingBottom = 0;
    chart.paddingTop = 0;

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "formato";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.fontSize = 10

    // Create value axis
    let  valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = "Quantità (Kg)";
    valueAxis.title.fontSize = 12
    valueAxis.renderer.labels.template.fontSize = 10
    valueAxis.strictMinMax = true;
    valueAxis.extraMax = 0.1;
    valueAxis.renderer.minGridDistance = 60

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#,###.";
    chart.language.locale["_thousandSeparator"] = ".";


    // Create series
    function createSeries(field, name, stacked, color) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "formato";
      series.dataFields.capacity = "val_capacita"
      series.dataFields.percLabel = "percSP"
      series.dataFields.perc = field === 'val_residuo' ? "percRes" : "percSP"
      series.name = name;
      series.columns.template.tooltipText = "[bold]{categoryY}[/] \n " +
        "Capacità: [bold]{capacity}[/] \n" +
        "{name}: [bold]{valueX}[/] ({perc}%)";
      series.stacked = stacked;
      series.stroke = color;
      series.fill = color;

      if (field !== 'val_residuo') {
        let valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{percLabel}%";
        valueLabel.label.truncate = false;
        valueLabel.label.horizontalCenter = "left";
        valueLabel.label.dx = 5;
        valueLabel.label.fontSize = 11;
      }
    }

    createSeries("val_supply_plan", "Pianificato", false, COLORS[props.plant]);
    createSeries("val_residuo", "Residuo", true, 'rgb(169,169,169)');

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.fontSize = 11;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 11;
    markerTemplate.height = 11;

    setChartRef(chart);

    return () => {
      chart.dispose();
    };

  }, [props.data, props.id, props.plant]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={props.id} style={{ minHeight: "250px", padding: 0 }} />
  );
}

export default React.memo(PlantDemandBarChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});
