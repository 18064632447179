import React, {useState, useEffect, useCallback} from "react";
import _ from "lodash";

import {TabPanel, a11yProps} from "../../components/Tabs/index";
import InfoSalesTable from "../../components/Tables/InfoSalesTable";
import ProductionCapacityTable from "../../components/Tables/ProductionCapacityTable";
import ProductionCapacitySummaryTable from "../../components/Tables/ProductionCapacitySummaryTable";
// import StockTable from "../../components/Tables/StockTable";
// import ProductionPlanTable from "../../components/Tables/ProductionPlanTable";

// Service
import ApiService from "../../services/ApiService";
import AuthenticationService from "../../services/AuthenticationService";

// UI
import {LinearProgress} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SimpleAlert from "../../components/Alert/SimpleAlert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";

let TABS = [
  {index: 0, id: "id", label: "Commerciali", table: "info_sales"},
  {
    index: 1,
    id: "id",
    label: "Capacità produttiva",
    table: "capacita_produttiva",
  },
  // {
  //   index: 2,
  //   id: "cod_materiale",
  //   label: "Stock",
  //   table: "stock",
  // },
  // {
  //   index: 3,
  //   id: "cod_ordine",
  //   label: "Pianificato produzione",
  //   table: "pianificato_produzione",
  // },
];

export default function InputSummary() {
  const userData = AuthenticationService.getUserData();
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [alert, setAlert] = useState({open: false, type: "", message: ""});
  const [data, setData] = useState({});
  const [dataProductionSummary, setDataProductionSummary] = useState(null);
  const [codeList, setCodeList] = useState();

  const [plant, setPlant] = useState("A");

  const handleRadioChange = (event) => {
    setPlant(event.target.value);
  };

  if (data) {
    console.log(data);
  }

  const handleChange = (event, newValue) => {
    console.log(`change tab to ${TABS[newValue]["table"]}`);
    setTabIndex(newValue);
  };

  const fetchData = ({table, id}) => {
    console.log(`fetching ${table}...`);
    setLoading(true);
    ApiService.getApi(`input-summary/${table}`)
        .then((result) => {
          setData((prevData) => ({
            ...prevData,
            [table]: _.keyBy(result, id),
          }));
        })
        .catch((err) => {
          console.error(`Error when fetching ${table}`, err);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const fetchProductionSummary = useCallback(() => {
    console.log(`fetching production summary...`);
    setLoading(true);
    ApiService.getApi(`input-summary/capacita-produttiva/summary/${plant}`)
        .then((result) => {
          setDataProductionSummary((prevData) => ({
            ...prevData,
            result,
          }));
        })
        .catch((err) => {
          console.error(`Error when fetching`, err);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [plant]);

  useEffect(() => {
    const item = TABS[tabIndex];
    if (!data[item.table]) {
      fetchData(item);
    }
  }, [data, tabIndex]);

  useEffect(() => {
    fetchProductionSummary();
  }, [data, plant, fetchProductionSummary]);

  const fetchCodeList = () => {
    console.log(`fetching code list...`);
    setLoading(true);
    ApiService.getApi(`input-summary/code-list`)
        .then((result) => {
          setCodeList(result);
        })
        .catch((err) => {
          console.error(`Error when fetching code list`, err);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  useEffect(() => {
    if (!codeList) {
      fetchCodeList();
    }
  }, [codeList]);

  const openAlert = (type, message) => {
    setAlert({
      open: true,
      type,
      message,
    });
  };

  const toggleAlert = () => {
    setAlert({
      ...alert,
      open: !alert.open,
    });
  };

  return (
      <>
        <LinearProgress style={{visibility: loading ? "visible" : "hidden"}}/>
        <AppBar position="static" color="default" style={{marginBottom: 20}}>
          <Tabs
              value={tabIndex}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
          >
            {userData.role !== "commerciale" &&
                TABS.map((item) => (
                    <Tab
                        key={item.index}
                        label={item.label}
                        {...a11yProps(parseInt(item.index))}
                    />
                ))}
            {userData.role === "commerciale" && (
                <Tab
                    key={TABS[0].index}
                    label={TABS[0].label}
                    {...a11yProps(parseInt(TABS[0].index))}
                />
            )}
          </Tabs>
        </AppBar>
        <TabPanel index={0} value={tabIndex}>
          {data && <InfoSalesTable
              data={data[TABS[0].table]}
              setData={setData}
              openAlert={openAlert}
              codeList={codeList}
          />}
        </TabPanel>
        <TabPanel index={1} value={tabIndex}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Stabilimento</FormLabel>
            <RadioGroup
                aria-label="Stabilimento"
                name="production_capacity"
                row
                value={plant}
                onChange={handleRadioChange}
            >
              <FormControlLabel
                  value="A"
                  control={<Radio/>}
                  label="A"
              />
              <FormControlLabel value="B" control={<Radio/>} label="B"/>
              <FormControlLabel
                  value="outsourcing"
                  control={<Radio/>}
                  label="Outsourcing"
              />
              <FormControlLabel
                  value="total"
                  control={<Radio/>}
                  label="Totale"
              />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={6} justify="space-around">
            <Grid item xs={8} sm={8}>
              {data && <ProductionCapacityTable
                  selection={plant}
                  data={data[TABS[1].table]}
                  setData={setData}
                  openAlert={openAlert}
                  setDataProductionSummary={setDataProductionSummary}
              />}
            </Grid>
            <Grid item xs={4} sm={4}>
              {dataProductionSummary && <ProductionCapacitySummaryTable
                  selection={plant}
                  data={dataProductionSummary}
              />}
            </Grid>
          </Grid>
        </TabPanel>
        {/*<TabPanel index={2} value={tabIndex}>*/}
        {/*  <StockTable data={data[TABS[2].table]} setData={setData} />*/}
        {/*</TabPanel>*/}
        {/*<TabPanel index={3} value={tabIndex}>*/}
        {/*  <ProductionPlanTable data={data[TABS[3].table]} setData={setData} />*/}
        {/*</TabPanel>*/}
        <SimpleAlert alert={alert} toggleAlert={toggleAlert}/>
      </>
  );
}
