import React, {useState, useEffect} from "react";
import {LinearProgress} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

import ApiService from "../../services/ApiService";
import AuthenticationService from "../../services/AuthenticationService";
import LinearProgressWithLabel from "../../components/Progress/LinearProgressWithLabel";
import PlantDemandBarChart from "../../components/Charts/PlantDemandBarChart";
import {COLORS} from "../../utils/constants";
import RevisionClosed from "../../components/Alert/RevisionClosed";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  alertInfo: {
    width: "100%",
    marginTop: theme.spacing(2),
    backgroundColor: "#2d798d",
  },
  alertWarning: {
    background: "#d29e0e",
    marginTop: theme.spacing(2),
  },
  alertSuccess: {
    background: "#48762a",
    marginTop: theme.spacing(2),
  },
}));

export default function Home() {
  const classes = useStyles();
  const userData = AuthenticationService.getUserData();
  const [loading, setLoading] = useState(false);
  const [dataSummary, setDataSummary] = useState({
    mese_previsione: "01-11-2021",
    val_codici_revisionati: 1090,
    val_codici_revisionare: 385,
    val_codici_assegnare: 72,
  });
  const [plantChartData, setPlantChartData] = useState(null);

  console.log(dataSummary)

  const DATA = {
    last_update_etl: dataSummary["tms_aggiornamento_etl"],
    reference_period: dataSummary["mese_previsione"],
    codes_reviewed: dataSummary["val_codici_revisionati"],
    codes_to_review: dataSummary["val_codici_revisionare"],
    codes_to_assign: dataSummary["val_codici_assegnare"],
  };

  const fetchDataSummary = () => {
    console.log(`fetching data summary ...`);
    setLoading(true);
    ApiService.getApi(`data-summary`)
      .then((result) => {
        setDataSummary(...result);
      })
      .catch((err) => {
        console.error(`Error when fetching data`, err);
      });
    setLoading(false);
  };

  const fetchPlantChartData = () => {
    console.log(`fetching plant chart data...`);
    setLoading(true);
    ApiService.getApi("make-or-buy/plant")
      .then((result) => {
        setPlantChartData(result);
      })
      .catch((err) => {
        console.error(`Error when fetching make or buy plant chart data`, err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!dataSummary) {
      fetchDataSummary();
    }
  }, [dataSummary]);

  useEffect(() => {
    if (!plantChartData) {
      fetchPlantChartData();
    }
  }, [plantChartData]);

  return (
    <>
      {userData && (
        <Grid container className={classes.header}>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom="true">
              Summary
            </Typography>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <p>
              Benvenuto, {userData.full_name}
            </p>
          </Grid>
        </Grid>
      )}
      <hr style={{marginTop: -5, marginBottom: 15}}/>
      <LinearProgress style={{visibility: loading ? "visible" : "hidden"}}/>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              {(DATA["codes_to_review"] > 0 || DATA["codes_reviewed"] > 0) && (
                <>
                  <Typography variant="body2">
                    Avanzamento Revisione della Domanda
                  </Typography>
                  <LinearProgressWithLabel
                    progress={
                      (DATA["codes_reviewed"] /
                        (DATA["codes_to_review"] + DATA["codes_reviewed"])) *
                      100
                    }
                  />
                  <Typography variant="body2">
                    {DATA["codes_reviewed"]} /
                    {DATA["codes_to_review"] + DATA["codes_reviewed"]} Codici
                    lavorati
                  </Typography>
                </>
              )}
              {!DATA["codes_to_review"] && !DATA["codes_reviewed"] && (
                <RevisionClosed/>
              )}
            </Paper>
          </Grid>
          {DATA && (<Grid item xs={4}>
            <Paper className={classes.paper}>
              <MuiAlert
                elevation={6}
                severity="info"
                variant="filled"
                className={classes.alertInfo}
              >
                Periodo di riferimento: <b>{DATA["reference_period"]}</b>
              </MuiAlert>
              <MuiAlert
                elevation={6}
                severity="info"
                variant="filled"
                className={classes.alertInfo}
              >
                Ultimo aggiornamento dati: <b>{DATA["last_update_etl"]}</b>
              </MuiAlert>
              {DATA["codes_to_assign"] > 0 ? (
                <MuiAlert
                  elevation={6}
                  severity="warning"
                  variant="filled"
                  className={classes.alertWarning}
                >
                  <b>
                    {DATA["codes_to_assign"]} Codici da assegnare in anagrafica
                  </b>
                </MuiAlert>
              ) : (
                <MuiAlert
                  elevation={6}
                  severity="success"
                  variant="filled"
                  className={classes.alertSuccess}
                >
                  <b>
                    {DATA["codes_to_assign"]} Codici da assegnare in anagrafica
                  </b>
                </MuiAlert>
              )}
            </Paper>
          </Grid>)}
          {plantChartData &&
            (DATA["codes_to_assign"] > 0 || DATA["codes_reviewed"] > 0) &&
            ["A", "B", "outsourcing"].map((plant) => (
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <p
                    style={{
                      color: COLORS[plant],
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      margin: 2,
                    }}
                  >
                    {plant}
                  </p>
                  {plantChartData &&
                    <PlantDemandBarChart
                      id={plant}
                      data={plantChartData.filter((r) => r['stabilimento'] === plant)}
                      plant={plant}
                    />}
                </Paper>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
}
