import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { MTS_MTO, PLANTS, FLAGS } from "../../utils/constants";

// UI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CenteredLoader from "../Loading/CenteredLoader";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyle = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function AssignMaterialDialog({
  open,
  toggleOpen,
  openAlert,
  codes,
  listReferenceCodes,
  editRow,
  inverse,
  resetData,
  data,
}) {
  const classes = useStyle();
  const [autoAssign, setAutoAssign] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(undefined);
  const [flags, setFlags] = useState({});
  const [stockOrder, setStockOrder] = useState("");
  const [plant, setPlant] = useState("");
  const [title, setTitle] = useState("");
  const [assignLabel, setAssignLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [makeSeriesTarget, setMakeSeriesTarget] = useState(false);
  const [seriesVolume, setSeriesVolume] = useState(0);

  useEffect(() => {
    setAutoAssign(false);
    setMakeSeriesTarget(false);
    setSeriesVolume(0);
    if (codes) {
      setTitle(
        inverse
          ? `Crea un nuovo riferimento a ${codes[0]}`
          : codes.length === 1
          ? `Assegna il materiale ${codes[0]}`
          : `Assegna i ${codes.length} materiali selezionati`
      );
      setAssignLabel(
        codes.length === 1
          ? "Il riferimento è se stesso"
          : "Assegnare ogni codice a se stesso"
      );
      if (
        data &&
        codes.length === 1 &&
        editRow &&
        editRow.cod_riferimento === editRow.cod_materiale
      ) {
        // check the code is a target of a multiple series
        setSeriesVolume(
          data.filter((i) => i.cod_riferimento === codes[0]).length
        );
      }
    }
  }, [codes, data, editRow, inverse]);

  useEffect(() => {
    if (editRow) {
      setFlags(
        Object.keys(FLAGS).reduce((o, i) => {
          return { ...o, [i]: editRow[i] };
        }, {})
      );
      setStockOrder(editRow["stock_order"]);
      setPlant(editRow["stabilimento"]);
      let target = { ...editRow, cod_materiale: editRow.cod_riferimento };
      setSelectedTarget(target);
    } else {
      setFlags(
        Object.keys(FLAGS).reduce((o, i) => {
          return { ...o, [i]: true };
        }, {})
      );
      setStockOrder(MTS_MTO[0]);
      setPlant(PLANTS[0]);
      setSelectedTarget(undefined);
    }
  }, [editRow]);

  const closeService = (outcome, toPrint) => {
    console.log(toPrint);
    setLoading(false);
    toggleOpen();
    setSelectedTarget(null);
    setAutoAssign(false);
    setMakeSeriesTarget(false);
    setSeriesVolume(0);
    resetData();
    const text =
      outcome === "success"
        ? "Assegnazione avvenuta con successo"
        : "Impossibile completare l'operazione";
    openAlert(outcome, text);
  };

  const assign = () => {
    setLoading(true);
    let target = selectedTarget ? selectedTarget.cod_materiale : null;
    if (editRow && !selectedTarget && editRow.cod_riferimento === codes[0]) {
      target = editRow.cod_riferimento;
    }
    console.log(`assegno ${codes.length} materiali a ${target}`);
    const data = {
      cod_materiali: codes,
      cod_riferimento: target,
      flg_stock: flags.flg_stock,
      flg_pianificato_produzione: flags.flg_pianificato_produzione,
      flg_ordini: flags.flg_ordini,
      stock_order: stockOrder,
      stabilimento: plant,
      inverse: inverse,
      make_target: makeSeriesTarget,
    };
    ApiService.postApi(`material-registry/assign`, data)
      .then((message) => {
        closeService("success", message);
      })
      .catch((err) => {
        closeService("error", err);
      });
  };

  function renderOption(option) {
    return (
      <span>
        <b>{option.cod_materiale}</b> <br />{" "}
        <small>{option.des_materiale}</small>
      </span>
    );
  }

  function onSelectionChange(event, selection) {
    setSelectedTarget(selection);
  }

  function onStockSelectionChange(event, selection) {
    setStockOrder(selection);
  }

  function onPlantSelectionChange(event, selection) {
    setPlant(selection);
  }

  const handleFlag = (e) => {
    const el = e.target.name;
    setFlags({ ...flags, [el]: !flags[el] });
  };

  const handleAutoAssign = () => {
    setAutoAssign(!autoAssign);
    if (!autoAssign) {
      setSelectedTarget(undefined);
      setMakeSeriesTarget(false);
    }
  };

  const handleMakeTarget = () => {
    setMakeSeriesTarget(!makeSeriesTarget);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {!inverse && (
            <FormControlLabel
              control={
                <Checkbox
                  key="auto-assign"
                  name="auto-assign"
                  onChange={handleAutoAssign}
                  checked={autoAssign}
                  margin="dense"
                  variant="outlined"
                  color="primary"
                  style={{ paddingRight: 2, marginRight: 2 }}
                />
              }
              label={assignLabel}
            />
          )}
          <Autocomplete
            value={selectedTarget || null}
            disabled={autoAssign}
            options={listReferenceCodes}
            getOptionLabel={(option) => option.cod_materiale}
            renderOption={renderOption}
            onChange={onSelectionChange}
            style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  inverse
                    ? "Importa riferimento esistente (opzionale)"
                    : "Riferimento"
                }
                variant="outlined"
              />
            )}
          />
          {codes &&
            codes.length === 1 &&
            editRow &&
            editRow.cod_riferimento !== editRow.cod_materiale &&
            autoAssign && (
              <FormControlLabel
                control={
                  <Checkbox
                    key="make-target"
                    name="make-target"
                    onChange={handleMakeTarget}
                    checked={makeSeriesTarget}
                    margin="dense"
                    variant="outlined"
                    color="primary"
                    style={{ paddingRight: 2, marginRight: 2 }}
                  />
                }
                label="Il codice fa parte di una serie: rendilo il nuovo riferimento"
              />
            )}
          <div style={{ textAlign: "center", marginTop: 15 }}>
            {Object.keys(flags).map((flag) => (
              <FormControlLabel
                control={
                  <Checkbox
                    key={flag}
                    name={flag}
                    onChange={handleFlag}
                    checked={flags[flag]}
                    margin="dense"
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ paddingRight: 1, marginRight: 2 }}
                  />
                }
                label={FLAGS[flag]}
              />
            ))}
          </div>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={5}>
              <Autocomplete
                value={stockOrder}
                options={MTS_MTO}
                onChange={onStockSelectionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock/Order"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={plant}
                options={PLANTS}
                onChange={onPlantSelectionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stabilimento"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          {seriesVolume > 1 && (
            <>
              <Divider variant="middle" style={{ marginTop: 15 }} />
              <Grid container>
                <Grid item xs={1}>
                  <ErrorOutlineIcon style={{ marginTop: 20 }} color="primary" />
                </Grid>
                <Grid item xs={11}>
                  <p>
                    Il codice è il riferimento di una serie di {seriesVolume}{" "}
                    materiali. <br />
                    Modificando il riferimento tutta la serie verrà riassociata.
                  </p>
                </Grid>
              </Grid>

              <Divider variant="middle" style={{ marginTop: 15 }} />
            </>
          )}
          {loading && <CenteredLoader title={""} />}
          <Grid container>
            <Grid item xs={5}>
              <Button
                onClick={toggleOpen}
                variant="contained"
                className={classes.button}
              >
                Esci
              </Button>
            </Grid>
            <Grid item xs={7} style={{ textAlign: "end" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={assign}
                disabled
                // disabled={
                //   (!selectedTarget && !autoAssign && !inverse) ||
                //   !stockOrder ||
                //   !plant
                // }
              >
                Assegna
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AssignMaterialDialog;
