import axios from "axios";
import {API_URL} from "../../utils/constants";
import AuthenticationService from "../AuthenticationService";

export default class ApiService {

  static getHeaders() {
    const token = AuthenticationService.getAuthToken();
    return {"Authorization": "Bearer " + token}
  }


  static async getApi(typeObject, args) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = args === undefined ? baseUrl : baseUrl + `${args}`;
    return await axios
      .get(url, {headers: this.getHeaders()})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async postApi(typeObject, data) {
    return await axios.post(`${API_URL}/${typeObject}/`, data, {headers: this.getHeaders()})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async deleteApi(typeObject) {
    return await axios.delete(`${API_URL}/${typeObject}/`, {headers: this.getHeaders()})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

}
