import React, {useCallback, useEffect, useState} from "react";

// UI
import {Button, LinearProgress, Typography} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

// Icons
import InputAdornment from "@material-ui/core/InputAdornment";
import SimpleAlert from "../../components/Alert/SimpleAlert";
import PublishIcon from "@material-ui/icons/Publish";
import SpeedIcon from "@material-ui/icons/Speed";
import EditIcon from "@material-ui/icons/Edit";

// Services
import DemandRevisionChart from "../../components/Charts/DemandRevisionChart";
import MakeSelectionDemand from "../../components/Alert/MakeSelectionDemand";
import {FILTER_DIMENSION, COLORS} from "../../utils/constants";
import ApiService from "../../services/ApiService";
import _ from "lodash";
import moment from "moment";
import RevisionClosed from "../../components/Alert/RevisionClosed";

const useStyles = makeStyles((theme) => ({
  percKpi: {
    fontSize: 15,
    fontWeight: "bold",
    color: theme.palette.primary.dark,
  },
  field: {
    marginRight: 12,
    marginBottom: 10,
    width: "7%",
  },
  textInside: {
    fontSize: 13,
    textAlign: "center",
  },
  textSP: {
    fontSize: 13,
    textAlign: "center",
    color: COLORS["val_supply_plan"],
    fontWeight: 500,
    paddingLeft: 2,
    paddingRight: 0,
  },
  Forecast: {"&$disabled": {color: COLORS["val_previsione"]}},
  Ordinato: {"&$disabled": {color: COLORS["val_ordine"]}},
  Settimana: {
    "&$disabled": {color: COLORS["dat_settimana"], fontWeight: "bold"},
  },
  Stock: {"&$disabled": {color: COLORS["val_proiezione_stock"]}},
  Pianificato: {"&$disabled": {color: COLORS["val_pianificato"]}},
  "Supply Plan": {"&$disabled": {color: COLORS["val_supply_plan"]}},
  disabled: {},
  grid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    width: 300,
    marginTop: -15,
    marginBottom: 25,
  },
}));

export default function DemandRevision() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("");
  const [codeList, setCodeList] = useState([]);
  const [filterDimension, setFilterDimension] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filteredCodes, setFilteredCodes] = useState();
  const [selectedCode, setSelectedCode] = useState(null);
  const [flgTodo, setFlgTodo] = useState(true);
  const [flgDone, setFlgDone] = useState(true);
  const [data, setData] = useState(null);
  const [counter, setCounter] = useState(null);
  const [alert, setAlert] = useState({open: false, type: "", message: ""});

  const openAlert = (type, message) => {
    setAlert({open: true, type, message});
  };

  const toggleAlert = () => {
    setAlert({...alert, open: !alert.open});
  };

  const fetchCodeList = useCallback(() => {
        console.log(`fetching code list...`);
        setLoading(true);
        ApiService.getApi(`demand-revision`)
            .then((result) => {
              setCodeList(result["codes"]);
              applyAllFilters();
              setPeriod(result["period"]);
              setLoading(false);
            })
            .catch((err) => {
              console.error(`Error when fetching code list`, err);
              setLoading(false);
            });
      }, // eslint-disable-next-line
      [flgTodo, flgDone]);

  const fetchData = useCallback(() => {
    console.log(`fetching data for UUID ${selectedCode.uuid}`);
    setLoading(true);
    ApiService.getApi(`demand-revision/${selectedCode.uuid}`)
        .then((result) => {
          setData(
              result.reduce((obj, week, index) => _.set(obj, index, week), {})
          );
          setLoading(false);
        })
        .catch((err) => {
          console.error(`Error when fetching data`, err);
          setLoading(false);
        });
  }, [selectedCode]);

  useEffect(() => {
    if (!codeList.length) {
      fetchCodeList();
    }
  }, [codeList, fetchCodeList]);

  useEffect(() => {
    setData(undefined);
    if (selectedCode) {
      fetchData();
    }
  }, [selectedCode, fetchData]);

  function applyAllFilters() {
    if (codeList.length) {
      setFilteredCodes(
          codeList.filter((i) => {
            return (
                (!filterValue || i[filterDimension["value"]] === filterValue) &&
                ((flgDone && i["flg_revisionato"]) ||
                    (flgTodo && !i["flg_revisionato"]))
            );
          })
      );
    }
  }

  function updateCounter() {
    const tmp = filterValue
        ? codeList.filter((i) => i[filterDimension["value"]] === filterValue)
        : codeList;
    const done = tmp.filter((i) => i["flg_revisionato"]).length;
    setCounter({done: done, todo: tmp.length - done});
  }

  useEffect(() => {
        applyAllFilters();
      },// eslint-disable-next-line
      [flgTodo, flgDone, codeList, filterValue]);

  useEffect(() => {
        updateCounter();
      }, // eslint-disable-next-line
      [codeList, filterValue]);

  function renderOption(option) {
    return (
        <span>
        <b>{option.cod_riferimento}</b> -{" "}
          <i>
          <small>
            {option["flg_revisionato"] && " Revisionato"}
            {!option["flg_revisionato"] && " Da revisionare"}{" "}
          </small>
        </i>
        <br/>
        <small>{option["des_materiale"]}</small>
      </span>
    );
  }

  function onDimensionChange(event, category) {
    setFilterDimension(category);
  }

  function onValueChange(event, value) {
    setFilterValue(value);
    if (
        value &&
        selectedCode &&
        selectedCode[filterDimension["value"]] !== value
    ) {
      setSelectedCode(undefined);
    }
  }

  const Title = () => (
      <Grid container style={{marginBottom: "10px"}}>
        <Grid item xs={6}>
          <Typography variant="h5">Revisione della domanda</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end">
            {period && (
                <Typography variant="h6">
                  Periodo di riferimento: <b>{period}</b>
                </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
  );

  function getDefaultValue(week, key) {
    if (key === "dat_settimana") {
      const value = data[week][key];
      return `${moment(value).format("MMM DD")}`;
    }
    if (data[week][key] === null) {
      return "----";
    }
    return Math.round(data[week][key]);
  }

  function updateSupplyPlan(week, value) {
    let tmp = _.clone(data);
    tmp[week]["val_supply_plan"] = Number(value) || 0;
    Object.keys(data).forEach((i) => {
      if (Number(i) >= Number(week)) {
        const from = tmp[i - 1]["val_proiezione_stock"];
        const toAdd1 = tmp[i]["val_pianificato"];
        const toAdd2 = tmp[i]["val_supply_plan"];
        const toSub = tmp[i]["val_previsione_corretta"];
        tmp[i]["val_proiezione_stock"] = from + toAdd1 + toAdd2 - toSub;
      }
    });
    setData(tmp);
  }

  function readOnlyBox(label, week, key) {
    const variant = key === "dat_settimana" ? "standard" : "outlined";
    const disabled =
        key !== "val_supply_plan" || !data[week]["flg_periodo_riferimento"];
    const inputClass =
        key === "val_supply_plan" ? classes.textSP : classes.textInside;
    let endAdornment;
    if (!disabled) {
      endAdornment = (
          <InputAdornment
              position="end"
              style={{marginRight: -6, marginLeft: 2}}
          >
            <EditIcon style={{fontSize: 12}}/>
          </InputAdornment>
      );
    }
    return (
        <TextField
            className={classes.field}
            value={getDefaultValue(week, key)}
            onChange={(e) => updateSupplyPlan(week, e.target.value)}
            disabled={disabled}
            variant={variant}
            size="small"
            InputProps={{
              classes: {
                root: classes[label],
                disabled: classes.disabled,
                input: inputClass,
              },
              endAdornment: endAdornment,
            }}
        />
    );
  }

  function rowTitle(text, color) {
    const margin = text === "Settimana" ? 0 : 6;
    return (
        <p style={{marginTop: margin, color: color}}>
          <b>{text}</b>
        </p>
    );
  }

  function tableRow(label, key) {
    return (
        <>
          <Grid item xs={1}>
            {rowTitle(label, COLORS[key])}
          </Grid>
          <Grid item xs={11}>
            {Object.keys(data).map((week) => readOnlyBox(label, week, key))}
          </Grid>
        </>
    );
  }

  function sendDemandRevision() {
    ApiService.postApi(`demand-revision/${selectedCode.uuid}`, _.values(data))
        .then((msg) => {
          console.log(msg);
          setSelectedCode(null);
          setData(null);
          fetchCodeList();
          openAlert("success", "Revisione salvata con successo");
        })
        .catch((err) => {
          console.log(err);
          openAlert("error", "Errore durante l'invio dei dati");
        });
  }

  console.log(data)

  return (
      <>
        <LinearProgress style={{visibility: loading ? "visible" : "hidden"}}/>
        <Title/>
        <hr style={{marginBottom: 20}}/>
        {filteredCodes && (counter["todo"] > 0 || counter["done"] > 0) && (
            <Grid container spacing={3}>
              <Grid item className={classes.grid}>
                <Autocomplete
                    id="dimension"
                    style={{width: 200}}
                    value={filterDimension || null}
                    options={FILTER_DIMENSION}
                    getOptionLabel={(i) => i.label}
                    renderOption={(i) => i.label}
                    onChange={onDimensionChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Filtra per:" variant="outlined"/>
                    )}
                />
              </Grid>
              <Grid item className={classes.grid}>
                <Autocomplete
                    id="value"
                    style={{width: 300}}
                    options={
                      filterDimension
                          ? _.sortBy(
                              _.uniq(
                                  codeList
                                      .filter((c) => {
                                        return (
                                            (flgDone && c["flg_revisionato"]) ||
                                            (flgTodo && !c["flg_revisionato"])
                                        );
                                      })
                                      .map((i) => i[filterDimension["value"]])
                              )
                          )
                          : []
                    }
                    value={filterValue || null}
                    onChange={onValueChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={filterDimension ? filterDimension["label"] : "-"}
                            variant="outlined"
                        />
                    )}
                />
              </Grid>
              <Grid item className={classes.grid}>
                <Autocomplete
                    id="code-selection"
                    style={{width: 350}}
                    value={selectedCode || null}
                    options={filteredCodes}
                    getOptionLabel={(option) => option.cod_riferimento}
                    renderOption={renderOption}
                    onChange={(e, s) => {
                      setSelectedCode(s);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Codice di riferimento"
                            variant="outlined"
                        />
                    )}
                />
              </Grid>
              <Grid item className={classes.grid}>
                <div style={{marginTop: -12}}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            key="todo"
                            onChange={() => setFlgTodo(!flgTodo)}
                            checked={flgTodo}
                            margin="dense"
                            variant="outlined"
                            color="primary"
                        />
                      }
                      label={`Da revisionare (${counter["todo"]})`}
                  />
                </div>
                <div style={{marginTop: -5}}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            key="done"
                            onChange={() => setFlgDone(!flgDone)}
                            checked={flgDone}
                            margin="dense"
                            variant="outlined"
                            color="primary"
                        />
                      }
                      label={`Revisionati (${counter["done"]})`}
                  />
                </div>
              </Grid>
              <Grid item className={classes.grid}>
                <SpeedIcon style={{fontSize: 30}}/>
                <div className={classes.percKpi}>
                  {(
                      (counter["done"] / (counter["todo"] + counter["done"])) *
                      100
                  ).toFixed(1)}
                  %
                </div>
              </Grid>
            </Grid>
        )}
        {!(counter && !counter["todo"] && !counter["done"]) &&
            !(selectedCode && data) && <MakeSelectionDemand/>}
        {selectedCode && data && (
            <>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12} style={{marginLeft: 10, marginTop: 10}}>
                      <b>{selectedCode["des_materiale"]}</b>
                    </Grid>
                    <Grid item style={{margin: 10}}>
                      <b>
                        <small>Formato: </small>
                      </b>{" "}
                      {selectedCode["tipo_lavorazione"]}
                    </Grid>
                    <Grid item style={{margin: 10}}>
                      <b>
                        <small>Stabilimento: </small>
                      </b>{" "}
                      {selectedCode["stabilimento"]}
                    </Grid>
                    <Grid item style={{margin: 10}}>
                      <b>
                        <small>MTS/MTO: </small>
                      </b>{" "}
                      {selectedCode["stock_order"]}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {selectedCode["note_commerciali"] && (
                      <Grid container>
                        <Grid item xs={12} style={{marginLeft: 10, marginTop: 10}}>
                          <b>Note commerciali:</b>
                        </Grid>
                        <Grid item style={{margin: 4}}>
                          {selectedCode["note_commerciali"].map((note) => (
                              <small>
                                <p>
                                  {note.dat_inizio} - {note.dat_fine}:{" "}
                                  <b>{note.motivazione}</b>, <i>volume: </i>
                                  <b>{note.volume_impianto}</b>, <i>note</i>:{" "}
                                  <b>{note.note}</b>{" "}
                                </p>
                                <br/>
                              </small>
                          ))}
                        </Grid>
                      </Grid>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PublishIcon/>}
                      onClick={sendDemandRevision}
                      style={{marginTop: 20}}
                  >
                    Approva codice
                  </Button>
                </Grid>
              </Grid>
              <DemandRevisionChart
                  id="demand-revision-chart"
                  data={_.values(data).map((week) => ({...week}))}
              />
              <Grid container style={{marginTop: 10}}>
                {tableRow("Settimana", "dat_settimana")}
                {tableRow("Ordinato", "val_ordine")}
                {tableRow("Forecast", "val_previsione")}
                {tableRow("Pianificato", "val_pianificato")}
                {tableRow("Supply Plan", "val_supply_plan")}
                {tableRow("Stock", "val_proiezione_stock")}
              </Grid>
            </>
        )}
        {counter && !counter["todo"] && !counter["done"] && <RevisionClosed/>}
        <SimpleAlert alert={alert} toggleAlert={toggleAlert}/>
      </>
  );
}
